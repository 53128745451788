@import "./styles";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/nouislider/distribute/nouislider.min.css";

body {
    padding-top: 74px;
    padding-bottom: 2rem;
    background-color: #f2f2f2 !important;
}

.bodyscrolllock {
    overflow: hidden !important;
}

* {
    font-family: "Roboto",sans-serif;
    font-size: 15px;
}

#section-navbar {
    position: fixed;
    top: 60px;
    z-index: 998;
    background-color: white;
    box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.16);

    .cancel-search{
        position: absolute;
        right: 15px;
    }
}
nav .nav-item .nav-link {
    path.cls-1{
        fill: $grey-zc;
        opacity: 0.5;
    }
}

h5 {
    color: $blue !important;
    text-transform: none !important;
    margin: 0 0 0.75rem 0 !important;
    font-size: 1.1rem !important;
    line-height: 1.2rem !important;
    font-weight: 500 !important;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

h5.subtitle {
    font-size: 1rem !important;
    line-height: 1.1rem !important;
    margin-bottom: 5px !important;
    margin-top: 13px !important;
}
h5.nosep {
    border-bottom: none;
}
h5.subtitle .fa,
h5.nosep .fa {
    color: #737373;
    margin-right: 5px;
    font-size: 1.3rem;
}
h5.nosep .fa {
    margin-right: 10px;
}
h5.black, h5.black .fa {
    color: #333 !important;
    font-size: 1.3rem !important;
}
h5.black {
    font-weight: bold !important;
}
h5.black .fa {
    font-weight: normal !important;
}
h5.rosa {
    color: $rosa-zc !important;
}
h6 {
    color: #4c4c4c;
    margin: 0 0 1.1rem;
    font-size: 1.1rem !important;
    font-weight: bold;
    padding: 0;
}
h6 .fa {
    color: #737373;
    margin-right: 10px;
}

.no-gutter {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
p {
    margin-bottom: 0.5rem !important;
}
p.condensed {
    margin-bottom: 0.15rem !important;
}
.bold {
    font-weight: bold !important;
}
small, .small {
    color: #5d5d5d;
    line-height: 1.5em;
}
section + section {
    margin-top: 3rem;
}
.zc-card-shad + .zc-card-shad {
    margin-top: 1.5rem;
}

.tooltip-inner {
    background-color: #333333;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #333333 !important;
}

.btn {
    letter-spacing: 0.5px;
    //box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    margin: 1rem 0 0;
}

.modal-footer .btn {
    margin-top: 0;
}

.modal-content{
	border: 0 !important;
}

.dialogs-body p {
    margin: 0 !important;
    font-size: 18px;
}
.dialogs-body p .fa {
    font-size: 30px;
    vertical-align: middle;
    margin-right: 4px;
    color: $blue-zc;
}

.btn-secondary {
    border-color: #808080;
    background-color: #808080;
}
.btn-secondary:hover, .btn-secondary:active {
    border-color: lighten(#808080, 5%);
    background-color: lighten(#808080, 5%);
}

.btn-teal {
    border-color: $teal-zc;
    background-color: $teal-zc;
    color: #fff;
}
.btn-teal:hover, .btn-teal:active, .btn-teal:focus {
    border-color: lighten($teal-zc, 5%);
    background-color: lighten($teal-zc, 5%);
    color: #fff;
}

.btn-mkt {
    font-size: 0.85rem;
    padding-left: .45rem;
    padding-right: .45rem;
}

.btn-facebook, .btn-color-facebook {
    background-color: #2D79A3;
    color: #fff;
}
.btn-facebook:hover, .btn-color-facebook:hover, .btn-color-facebook:active, .btn-color-facebook:focus {
    background-color: #3186b5;
    color: #fff;
}
.btn-facebook .fa {
    font-size: 1.8rem;
    vertical-align: text-bottom;
}

.violet-zc-dark {
    border-color: $violet-zc-dark;
    background-color: $violet-zc-dark;
    color: #fff;
}
.blue-zc-dark {
    border-color: $blue-zc-dark;
    background-color: $blue-zc-dark;
    color: #fff;
}
.blue-zc-color {
    color: $blue-zc;
}
.blue-zc-dark-color {
    color: $blue-zc-dark;
}
.rosa-zc-color {
    color: $rosa-zc;
}
.red-zc-color {
    color: $red-zc;
}
.teal-zc-color {
    color: $teal-zc;
}
.gray-zc-color {
    color: $graytext;
}
.rosa-zc {
    border-color: $rosa-zc;
    background-color: $rosa-zc;
    color: #fff;
}
.btn-lightgray-zc {
    border-color: #ebebeb;
    background-color: #ebebeb;
    color: #fff;
}
.btn-gray-zc {
    border-color: $graytext;
    background-color: $graytext;
    color: #fff;
}
.btn-gray-zc:hover, .btn-gray-zc:focus, .btn-gray-zc:active {
    border-color: lighten($graytext, 3%);
    background-color: lighten($graytext, 3%);
    color: #fff;
}
.btn-blue-zc {
    border-color: $blue;
    background-color: $blue;
    color: #fff;
}
.btn-blue-zc:hover, .btn-blue-zc:focus, .btn-blue-zc:active {
    border-color: lighten($blue, 3%);
    background-color: lighten($blue, 3%);
    color: #fff;
}
.btn-rosa-zc {
    border-color: $rosa-zc;
    background-color: $rosa-zc;
    color: #fff;
}
.btn-rosa-zc:hover, .btn-rosa-zc:focus, .btn-rosa-zc:active {
    border-color: lighten($rosa-zc, 3%);
    background-color: lighten($rosa-zc, 3%);
    color: #fff;
}
.rosa-zc-dark {
    border-color: $rosa-zc-dark;
    background-color: $rosa-zc-dark;
    color: #fff;
}
.green-zc-dark {
    border-color: $green-zc-dark;
    background-color: $green-zc-dark;
    color: #fff;
}
.green-zc-darker {
    border-color: $green-zc-darker;
    background-color: $green-zc-darker;
    color: #fff;
}
.orange-zc-dark {
    border-color: $orange-zc-dark;
    background-color: $orange-zc-dark;
    color: #fff;
}

.orange-zc-color {
    color: $orange-zc;
}


.violet-zc-dark-color {
    color: $violet-zc-dark;
}
.violet-zc-dark:hover {
    border-color: lighten($violet-zc-dark, 7.5%);
    background-color: lighten($violet-zc-dark, 7.5%);;
    color: #fff;
}
.blue-zc-dark:hover {
    border-color: lighten($blue-zc-dark, 7.5%);;
    background-color: lighten($blue-zc-dark, 7.5%);;
    color: #fff;
}
.rosa-zc:hover {
    border-color: lighten($rosa-zc, 7.5%);;
    background-color: lighten($rosa-zc, 7.5%);;
    color: #fff;
}
.rosa-zc-dark:hover {
    border-color: lighten($rosa-zc-dark, 7.5%);;
    background-color: lighten($rosa-zc-dark, 7.5%);;
    color: #fff;
}
.green-zc-dark:hover {
    border-color: lighten($green-zc-dark, 7.5%);;
    background-color: lighten($green-zc-dark, 7.5%);;
    color: #fff;
}
.green-zc-darker:hover {
    border-color: lighten($green-zc-darker, 7.5%);;
    background-color: lighten($green-zc-darker, 7.5%);;
    color: #fff;
}
.orange-zc-dark:hover {
    border-color: lighten($orange-zc-dark, 7.5%);;
    background-color: lighten($orange-zc-dark, 7.5%);;
    color: #fff;
}

.badge-danger {
    background-color: $red-zc;
}

body.gender-1 nav .nav-item.active .nav-link {
	span:not(.badge){
		color: $blue-zc !important;
		font-weight: 500;
	}
	path, circle{
		fill: $blue-zc !important;
	}
	path.cls-1{
		fill: $blue-zc !important;
		opacity: 0.5;
	}
}
body.gender-2 nav .nav-item.active .nav-link {
	span:not(.badge){
		color: $rosa-zc !important;
		font-weight: 500;
	}
	path, circle{
		fill: $rosa-zc !important;
	}
	path.cls-1{
		fill: $rosa-zc !important;
		opacity: 0.5;
	}
}

body.gender-1 .gender-color-invert-tag {
	background: $blue-zc !important;
}
body.gender-2 .gender-color-invert-tag {
	background: $rosa-zc !important;
}

body.gender-1 .gender-color-invert-edit {
	path, circle{
		fill: $blue-zc !important;
	}
	path.cls-2{
		fill: #fff !important;
	}
}
body.gender-2 .gender-color-invert-edit {
	svg{
		path, circle{
			fill: $rosa-zc !important;
		}
		path.cls-2{
			fill: #fff !important;
		}
	}
}

body.gender-1 .gender-color {
    color: $blue !important;
}

body.gender-2 .gender-color {
    color: $rosa-zc !important;
}

body.gender-1 .gender-color-invert {
    color: $rosa-zc !important;
}

body.gender-2 .gender-color-invert {
    color: $blue !important;
}

body.gender-1 .btn-gender-color {
    border-color: $blue;
    background-color: $blue;
    color: #fff;
}

body.gender-1 .btn-gender-color-hover:hover, body.gender-1 .btn-gender-color-hover:focus, body.gender-1 .btn-gender-color-hover:active,
body.gender-1 .btn-gender-color:hover, body.gender-1 .btn-gender-color:focus, body.gender-1 .btn-gender-color:active {
    border-color: lighten($blue, 3%);
    background-color: lighten($blue, 3%);
    color: #fff;
}

body.gender-2 .btn-gender-color {
    border-color: $rosa-zc;
    background-color: $rosa-zc;
    color: #fff;
}

body.gender-2 .btn-gender-color-hover:hover, body.gender-2 .btn-gender-color-hover:focus, body.gender-2 .btn-gender-color-hover:active,
body.gender-2 .btn-gender-color:hover, body.gender-2 .btn-gender-color:focus, body.gender-2 .btn-gender-color:active {
    border-color: lighten($rosa-zc, 3%);
    background-color: lighten($rosa-zc, 3%);
    color: #fff;
}

.gender-color-1 {
    color: $blue !important;
}

.gender-color-2 {
    color: $rosa-zc !important;
}

.zc-card-shad {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

.roundbutton {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.5rem;
    border-radius: 1.5rem;
    vertical-align: middle;
    margin-bottom: 10px;
    span{
        color: #fff;
        font-size: 1.5rem;
    }
}

.mt20 {
    margin-top: 20px;
}
.mt30 {
    margin-top: 30px;
}

.tab-style-container {
    // display: flex !important;
}
.zc-card-shad .tab-style-container {
    margin: 0 -20px;
}
.gray-border-bot {
    border-bottom: 1px solid #ededed;
}

/* Messages actions */
body.gender-2 #messages-actions-container { color: $rosa-zc !important; }
body.gender-2 #messages-actions-container .mat-checkbox .mat-checkbox-frame  {
	border-color: $rosa-zc !important;
}
body.gender-1 #messages-actions-container { color: $blue-zc !important; }
body.gender-1 #messages-actions-container .mat-checkbox .mat-checkbox-frame  {
	border-color: $blue-zc !important;
}
body.gender-2 #messages-actions-container a {
	color: lighten($rosa-zc, 10%) !important;
	svg-icon{
		path {
			fill: lighten($rosa-zc, 10%) !important;
		}
	}
}

body.gender-2 #messages-actions-container a:hover,
				#messages-actions-container a:active,
				#messages-actions-container a:focus {
	color: $rosa-zc !important;
	svg-icon{
		path {
			fill: $rosa-zc !important;
		}
	}
}

body.gender-1 #messages-actions-container a {
	color: lighten($blue-zc, 10%) !important;
	svg-icon{
		path {
			fill: lighten($blue-zc, 10%) !important;
		}
	}
}

body.gender-1 #messages-actions-container a:hover,
#messages-actions-container a:active,
#messages-actions-container a:focus {
	color: $blue-zc !important;
	svg-icon{
		path {
			fill: $blue-zc !important;
		}
	}
}
/* End Messages actions */

/* Nav Principal */
.tab-style-container .tab-style-link {
    line-height: 3rem;
    text-align: center;
    flex: 1 1 auto !important;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    transition: .25s ease all;
    border-bottom: 1px solid transparent;
    display: block;
    svg{
        transition: .25s ease all;
        opacity: 0.3;
    }
}
.tab-style-container .tab-style-link .fa {
    margin-right: 3px;
}

body.gender-1 .guardarCambiosPerfil{
	border-color: lighten($blue-zc, 3%);
	background-color: lighten($blue-zc, 3%);
	color: #fff; }
body.gender-2 .guardarCambiosPerfil{
	border-color: lighten($rosa-zc, 3%);
	background-color: lighten($rosa-zc, 3%);
	color: #fff;}

.apodoFicha {
	font-weight: 600;
}
body.gender-1 .apodoFicha { color: $rosa-zc; }
body.gender-2 .apodoFicha { color: $blue-zc; }

.titleRecomendados {
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 2.8rem;
}

body.gender-1 .titleRecomendados { color: $blue-zc; }
body.gender-2 .titleRecomendados { color: $rosa-zc; }

.profileApodoRecomendados{
	font-size: 1.5rem;
	font-weight: bold;
}

body.gender-1 .profileApodoRecomendados { color: $rosa-zc }
body.gender-2 .profileApodoRecomendados { color: $blue-zc }

body.gender-1 .tab-style-container .tab-style-link.gender-color-tab {
	color: lighten($blue-zc, 20%) !important;
	svg-icon{
		path {
			fill: lighten($blue-zc, 20%) !important;
		}
	}
}
body.gender-2 .tab-style-container .tab-style-link.gender-color-tab {
	color: lighten($rosa-zc, 20%) !important;
	svg-icon{
		path {
			fill: lighten($rosa-zc, 20%) !important;
		}
	}
}
body.gender-1 .tab-style-container .tab-style-link.fa .gender-color-tab { color: $blue-zc; }
body.gender-2 .tab-style-container .tab-style-link.fa .gender-color-tab { color: $rosa-zc; }



body.gender-1 .tab-style-container
			.tab-style-link:hover, .tab-style-link:focus,
			.tab-style-link:active
			.gender-color-tab{
	color: $blue-zc !important;
	border-bottom: 1px solid $blue-zc !important;
	box-shadow: inset 0px -2px 0px 0px $blue-zc !important;
	svg-icon{
		path {
			fill: $blue-zc !important;
		}
	}
}
body.gender-2 .tab-style-container
			.tab-style-link:hover, .tab-style-link:focus,
			.tab-style-link:active
			.gender-color-tab{
	color: $rosa-zc !important;
	border-bottom: 1px solid $rosa-zc !important;
	box-shadow: inset 0px -2px 0px 0px $rosa-zc !important;
	svg-icon{
		path {
			fill: $rosa-zc !important;
		}
	}
}
.tab-style-container .tab-style-link:hover, .tab-style-link:focus, .tab-style-link:active {
    text-decoration: none;
    svg{
        opacity: 1;
    }
}

body.gender-1 .tab-style-container .tab-style-link.gender-color-tab.tab-style-active{
	color: $blue-zc !important;
	border-bottom: 1px solid $blue-zc !important;
	box-shadow: inset 0px -2px 0px 0px $blue-zc !important;
	svg{
		opacity: 1 !important;
		path {
			fill: $blue-zc !important;
		}
	}
}
body.gender-2 .tab-style-container .tab-style-link.gender-color-tab.tab-style-active{
	color: $rosa-zc !important;
	border-bottom: 1px solid $rosa-zc !important;
	box-shadow: inset 0px -2px 0px 0px $rosa-zc !important;
	svg{
		opacity: 1;
		path {
			fill: $rosa-zc !important;
		}
	}
}
/* Fin nav main */

.alert {
    word-break: break-word;
}
.alert-info {
    background-color: $highlight-item;
    color: $blue-zc-dark;
}

zc-sidebar .btn-mkt {
    margin-left: 7px;
}

zc-sidebar .showaffixed {
    position: fixed;
    opacity: 0;
    width: 280px !important;
    -webkit-transition: .3s ease opacity;
    transition: .3s ease opacity;
    top: -1000px;
}
zc-sidebar.affixed .showaffixed {
    opacity: 1;
}

zc-sidebar:not(.affixed) .affix-2 {
    top: -1000px !important;
}


zc-sidebar.affixed .affix-1 {
    top: 100px;
}
zc-sidebar.affixed .affix-2 {
    //top: 375px;
}

zc-sidebar {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

zc-sidebar .zc-card-shad img {
    max-width: 100%;
}

zc-sidebar .title-panel-mkt {
    font-size: .90rem;
    font-weight: bold;
    color: #4d4d4d;
    text-transform: uppercase;
    margin: 0 0 1rem;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 15px;
}

.sidetoside-20 {
    margin-left: -20px;
    margin-right: -20px;
}

/*.toast-container {
    z-index: $z-ix-toastr !important;
}*/
.toast-close-button{
    position: absolute!important;
    right: 12px!important;
    top: 0px!important;
    span{
        font-size: 20px;
        font-weight: 300;
    }
}
.toast-info {
    background-color: #389ED0 !important;
    background-image: none !important;
    padding: 20px !important;
}
.toast-success {
    background-color: #758080 !important;
    background-image: none !important;
    padding: 20px !important;
}
.ngx-toastr .toast-title {
    display: block;
    margin-bottom: 4px;
    font-size: 1.2rem;
}
.ngx-toastr img.toast-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    float: left;
    margin-right: 20px;
}
.ngx-toastr img.toast-type {
    height: 25px;
    width: 25px;
}
.toast-progress{
    background-color: white !important;
    margin: 6px 20px !important;
    opacity: 0.8 !important;
    height: 3px !important;
    max-width: 86% !important;
}

.toast-top-right {
    top: 74px !important;
}
.toast-container.toast-top-center .ngx-toastr, .toast-container.toast-bottom-center .ngx-toastr {
    margin-top: 74px;
    width: 500px !important;
}

.hovertdnone:hover, .hovertdnone:active, .hovertdnone:focus {
    text-decoration: none;
}

.zcinput {
    border: 0;
    border-bottom: 1px solid #9e9e9e;
    color: $blue-zc;
    padding-left: 0;
}

label + .zcinput {
    margin-left: 10px;
}

.zcinput:active, .zcinput:focus, .zcinput:hover {
    border-bottom: 1px solid $blue-zc;
    box-shadow: 0 1px 0 0 $blue-zc;
    color: $blue-zc;
}
.zcinput.zcinput-teal {
    color: $teal-zc;
}
.zcinput.zcinput-teal:active, .zcinput.zcinput-teal:focus, .zcinput.zcinput-teal:hover {
    border-bottom: 1px solid $teal-zc;
    box-shadow: 0 1px 0 0 $teal-zc;
    color: $teal-zc;
}

.zcinput.zcinput-edad {
    display: inline-block;
    width: 50px;
    text-align: center;
}

.form-group label + .zcinput {
    margin-left: 0;
    padding-top: 0;
    height: calc(1.5em + 0.375rem + 2px);
    margin-bottom: 1.5rem;
}

#signupmain .mat-form-field-subscript-wrapper, #login .mat-form-field-subscript-wrapper {
    margin: 0;
    position: relative !important;
}
mat-error {
    margin-top: 6px;
}

#login .form-group label + .zcinput,
#signupmain .form-group label + .zcinput,
#forgotMain .form-group label + .zcinput,
#resetpwdmain .form-group label + .zcinput {
    margin-bottom: 0;
}


.zcdropdown {
    border: 0;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    padding-left: 0;
}
.zcdropdown:active, .zcdropdown:focus, .zcdropdown:hover {
    border-bottom: 1px solid $blue-zc;
    box-shadow: 0 1px 0 0 $blue-zc;
    color: #4d4d4d;
}

.ptmateq {
    padding-top: .4375em;
}
.ptmateqh5 {
    padding-top: .15em;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $blue-zc;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $blue-zc;
}
.mat-radio-button.radio-blue-zc.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $blue-zc;
}
.mat-radio-button.radio-blue-zc.mat-accent .mat-radio-inner-circle,
.mat-radio-button.radio-blue-zc.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.radio-blue-zc.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.radio-blue-zc.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $blue-zc;
}

.mat-radio-button.radio-rosa-zc.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $rosa-zc;
}
.mat-radio-button.radio-rosa-zc.mat-accent .mat-radio-inner-circle,
.mat-radio-button.radio-rosa-zc.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.radio-rosa-zc.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.radio-rosa-zc.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $rosa-zc;
}

#slider-container .tp-bullets .bullet{
    margin: 0 10px 20px 0;
    text-decoration: none!important;
    display:inline-block!important;
    width:10px!important;
    height:10px!important;
    background: transparent url('/assets/images/slider_main_dot.png') no-repeat 0px 0px!important;
    text-indent:-999em!important;
    background-size: 10px 10px!important;
}
#slider-container .tp-bullets .bullet.selected {
    background: transparent url('/assets/images/slider_main_dot_active.png') no-repeat 0px 0px!important;
    background-size: 10px 10px!important;
}

#foo2_pag {
    margin: 20px auto;
    width: 50px;
}

#foo2_pag a {
    margin: 0 5px 0 0 !important;
    text-decoration: none!important;
    display:inline-block!important;
    width:8px!important;
    height:8px!important;
    background: transparent url('/assets/images/slider_dot.png') no-repeat 0px 0px!important;
    text-indent:-999em!important;
    background-size: 8px 8px!important;
}

#foo2_pag a.selected {
    background: transparent url('/assets/images/slider_dot_active.png') no-repeat 0px 0px!important;
    background-size: 8px 8px!important;
}

/* Rango edad slider */
.ng5-slider {
    margin: 20px 0 0px !important;
}

body.gender-2 .ng5-slider .ng5-slider-bar {background-color: $rosa-light-zc !important;}
body.gender-1 .ng5-slider .ng5-slider-bar {background-color: $blue-light-zc !important;}

body.gender-2 .ng5-slider .ng5-slider-pointer {
    width: 12px !important;
    height: 12px !important;
    border-radius: 11px !important;
    top: -4px !important;
    background-color: $rosa-zc !important;
    outline: 0;
}
body.gender-1 .ng5-slider .ng5-slider-pointer {
	width: 12px !important;
	height: 12px !important;
	border-radius: 11px !important;
	top: -4px !important;
	background-color: $blue-zc !important;
	outline: 0;
}

.ng5-slider .ng5-slider-pointer:after {
    display: none;
}
.ng5-slider .ng5-slider-bar-wrapper {
    visibility: inherit!important;
}

body.gender-1 .ng5-slider .ng5-slider-selection { background:$blue-zc!important}
body.gender-2 .ng5-slider .ng5-slider-selection { background:$rosa-zc!important}
/* End Rango edad slider */

.mat-select-value {
    color: #9e9e9e;
}

.mat-form-field.mat-focused .mat-select-value {
    color: #4d4d4d;
}
.mat-option.mat-active {
    background-color: $highlight-item !important;
}
.mat-select-trigger {
    min-width: 65% !important;
}
.mat-select-panel {
    max-height: 350px !important;
}

// Custom ZC input style
.mat-form-field-flex > .mat-form-field-infix { padding: 0.6em 0px 0.75em 0 !important;}
.mat-form-field-label-wrapper { top: -1.1em; }
.mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
}

// Mat-select label
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label {
    transform: translateY(-1.4em) scale(.75);
    width: 133.33333%;
	color: lightslategrey;
}
body.gender-1 .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label{
	color: $blue-zc;
}
body.gender-2 .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label{
	color: $rosa-zc;
}

// Mat-select error
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-invalid .mat-form-field-label{
    color: #f44336;
}

// Mat-select border
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: lightslategrey;
}
body.gender-1 .mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: $blue-zc;
}
body.gender-1 .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $blue-zc;
}
body.gender-2 .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: $rosa-zc;
}
body.gender-2 .mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: $rosa-zc;
}

// SIDEBAR, BUSCADOR y AVANZADO
body.gender-1 .buscador {
	h4 { color: $blue-zc !important; }
	h4 svg #icon { fill: $blue-zc !important; }
	.buscar {
		background-color: $blue-zc !important;
	}
}
body.gender-1 .buscador-avanzado {
	h4 { color: $blue-zc !important };
	h4 svg #icon { fill: $blue-zc !important; }
	.buscar {
		background-color: $blue-zc !important;
	}
}

body.gender-2 .buscador {
	h4 { color: $rosa-zc !important; }
	h4 svg #icon { fill: $rosa-zc !important; }
	.buscar {
		background-color: $rosa-zc !important;
	}
}
body.gender-2 .buscador-avanzado {
	h4 { color: $rosa-zc !important };
	h4 svg #icon { fill: $rosa-zc !important; }
	.buscar {
		background-color: $rosa-zc !important;
	}
}

body.gender-1 .complitud {
	p { color: $blue-zc !important; }
	.completar-perfil { background-color: $blue-zc !important; }
	.progress-bar{ background-color: $blue-zc !important; }
}
body.gender-2 .complitud {
	p { color: $rosa-zc !important; }
	.completar-perfil { background-color: $rosa-zc !important; }
	.progress-bar{ background-color: $rosa-zc !important; }
}
//

// APP EMPTY
body.gender-1 .empty-container-sidebar h6{ color: $blue-zc !important; }
body.gender-2 .empty-container-sidebar h6{ color: $rosa-zc !important; }
// END APP EMPTY

/*.buscador, .buscador-avanzado {
    h4 svg #icon{
        fill: $rosa-zc!important;
    }
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        color: $graytext;
    }
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: $zc-body-color ;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: $zc-body-color;
    }
    input.mat-input-element {
        color: $zc-body-color;
    }

    .mat-select-value-text span{
        color: $grey-zc-dark !important;
    }
}*/
/* END BUSCADOR */

// Hack svg dashboard user card
.nomegustaaction #icon{
    fill: $rosa-zc !important;
}
#messages-actions-container path.cls-1{
    fill: $blue-zc!important;
}

// Hack svg edit profile
.tag-container .profiletagitem path.st0{
    fill: white!important;
}
.languages-container{
    .mat-checkbox-inner-container{
        height: 18px!important;
        width: 18px!important;
    }
    .mat-checkbox-label{
        margin-left: 8px!important;
        font-size: 1rem!important;
    }
    .mat-checkbox{
        margin-left: 10%;
    }
}
.edit-profile-dialog svg-icon path{
    fill: white;
}
.edit-profile-dialog svg-icon.modal-close path{
    fill: $grey-zc-dark !important;
}
.edit-profile-dialog .image-default.selected svg-icon path{
    fill: $grey-zc-dark;
}

.edit-profile-dialog .upload-options svg-icon path{
    fill: $blue-zc;
}

// ACCOUNT
#my-account {
    .notifications{
        .mat-slide-toggle-label{
            margin: 0!important;
            height: 30px!important;
        }
    }
}
.baja-dialog mat-dialog-container, .change-pass-dialog mat-dialog-container{
    border-radius: 10px;
    overflow: hidden;
}
.edit-profile-dialog mat-dialog-container{
    padding: 0!important;
}


.descriptioncard mat-form-field{
    .mat-form-field-wrapper, .mat-form-field-flex, .mat-form-field-infix, textarea{
        height: 99% !important;
        width: 100%;
        resize: none;
    }
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: rgba(0,0,0,.6);
}

.advsrc .mat-form-field-infix {
    border-top-width: 0 !important;
    overflow: hidden;
}

.advsrc .mat-form-field-underline {
    bottom: 0.25em;
}

.advsrc .mat-form-field-wrapper{
    padding-bottom: 0;
}

.signupfield .mat-form-field-infix {
    border-top-width: 0 !important;
    overflow: hidden;
}

.signupfield .mat-form-field-underline {
    bottom: 0.25em;
}

.signupfield .mat-form-field-wrapper {
    padding-bottom: 0;
}
.signupfield .mat-select-value {
    color: $blue-zc;
}

.profilematcontrol .mat-form-field-infix {
    overflow: hidden;
}

.profilematcontrol .mat-form-field-underline {
    bottom: 0.25em;
}

.profilematcontrol .mat-form-field-wrapper{
    padding-bottom: 0;
}

.profilematcontrol-vert .mat-form-field-infix {
    border-top-width: 0 !important;
    overflow: hidden;
    padding: .2375em 0 .4375em;
}

.zcinput-vert {
    height: calc(1em + 0.25em + 0.15em + 2px);
    padding: 0.25em 0 0.15em 0;
}

/* CheckBox */
body.gender-1 .mat-checkbox .mat-checkbox-frame  {
	border-color: $blue-zc !important;
}
body.gender-2 .mat-checkbox .mat-checkbox-frame  {
	border-color: $rosa-zc !important;
}
body.gender-1 .mat-checkbox-checked.mat-accent .mat-checkbox-background,
				.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background: $blue-zc !important;
}
body.gender-2 .mat-checkbox-checked.mat-accent .mat-checkbox-background,
				.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background: $rosa-zc !important;
}
/* End Checbox*/
/* EJ: propiedades CHECK .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate,
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background: $blue-zc !important;
}*/

.cdk-overlay-container {
    z-index: 26000;
}

// Completation bar
body.gender-1 .profilecompletion.gender-color-progress {
	p { color: $blue-zc; }
	.button-container{
		a { background-color: lighten($blue-zc, 5%) !important; color: white; }
		a:hover{ background-color: $blue-zc !important; }
	}
}
body.gender-2 .profilecompletion.gender-color-progress {
	p { color: $rosa-zc; }
	.button-container{
		a { background-color: lighten($rosa-zc, 5%) !important; color: white; }
		a:hover{ background-color: $rosa-zc !important; }
	}
}
//
.profileprogress { height: 0.7rem; border-radius: 2px; }
body.gender-1 .profilecompletion.gender-color-progress .profileprogress {
	background: lighten($blue-zc, 20%) !important;
}
body.gender-2 .profilecompletion.gender-color-progress .profileprogress {
	background: lighten($rosa-zc, 20%) !important;
}
.profileprogress .progress-bar,
#profilecompletion .progress-bar {
    width: 0%;
    transition: .20s ease width;
}
body.gender-1 .profilecompletion.gender-color-progress .progress-bar {
	background: $blue-zc!important;
}
body.gender-2 .profilecompletion.gender-color-progress .progress-bar {
	background: $rosa-zc!important;
}
// END Completation bar

.modal {
    z-index: $z-ix-modal;
}
.modal-content {
    border: 2px solid $blue-zc;
}
.modal-backdrop {
    z-index: $z-ix-modal-backdrop !important;
}
.modal-body > h5 {
    margin-top: 10px !important;
}

.form-group label {
    margin-bottom: 0;
}

.buttons-flex {
    display: flex !important;
}
.buttons-flex app-loading-sm {
    width: 100%;
    margin-left: 5px;
    margin-right: 10px;
    margin-top: 15px;
}
.buttons-flex .btn {
    margin-right: 5px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}
.buttons-flex .btn + .btn {
    margin-left: 5px;
    margin-right: 0;
}
.buttons-flex .btn + .btn + .btn {
    margin-left: 10px;
    margin-right: 0;
}

.hidden-md-up {
    display: none;
}

.profiletag-container ngb-typeahead-window {
    overflow: hidden;
    max-width: 400px;
}

@media (max-width: 768px) {

    body {
        padding-top: 65px;
        padding-bottom: 1rem;
    }

    .toast-container.toast-top-center .ngx-toastr {
        width: 90% !important;
    }

    .mt06remxs {
        margin-top: 0.6rem;
    }

    zc-sidebar {
        display: none !important;
    }

    .hidden-xs-down {
        display: none !important;
    }
    .hidden-md-up {
        display: inherit !important;
    }
    strong.hidden-md-up,
    span.hidden-md-up {
        display: inline !important;
    }


    .tab-style-container .tab-style-link {
        font-size: 0.9em;
    }

    .mat-select-panel {
        max-height: 250px !important;
    }

    .zcinput.zcinput-edad {
        width: 35px;
    }

    #mainbodycontainer {
        padding-left: 0;
        padding-right: 0;
    }
    #mainbodycontainer > .row {
        margin-right: 0;
        margin-left: 0;
    }
    #mainbodycontainer > .row > .col-12 {
        padding-left: 0;
        padding-right: 0;
    }

    .profilematcontrol .mat-form-field-infix {
        border-top-width: .1em !important;
    }


    // emojimart picker
    .emoji-mart {
        width: 100% !important;
    }

    .profiletag-container ngb-typeahead-window {
        overflow: hidden;
        max-width: 100vw;
    }
}

// emojimart picker
.emoji-mart-bar {
    border: 0;
}
.emoji-mart-preview, .emoji-mart-search {
    display: none;
}

span.emoji-mart-anchor {
    cursor: pointer;
}

.emoji-mart-category-label span {
    position: inherit !important;
}

.emoji-mart-category-label span {
    font-size: 13px;
    padding: 4px 6px;
    font-weight: normal;
    color: $graytext;
}

#toTop {
    text-align: center;
    text-indent: 0;
    vertical-align: middle;
    background: rgba(0,0,0,.5)none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 20px;
    right: 20px;
    position:fixed;
    overflow:hidden;
    border:none;
    display: block;
    -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease;


    &:active, &:focus {
        outline:none;
    }

    &:hover {
        background: #e61594;
    }

    .fa {
        font-size: 20px;
        color: #fff;
    }
}

/*--- HOME ---*/
#slider-container:hover .tparrows.default {
    opacity: 1;
    filter: alpha(opacity=100)
}

#slider-container .tparrows.default {
    height: 50px;
    line-height: 100%;
    background: rgba(44, 62, 80, .5) none;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease
}

#slider-container .tparrows.default:hover {
    background-color: #E61594
}

#slider-container .tparrows.default:before {
    font-family: FontAwesome, sans-serif;
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 1em;
    height: 1em;
    line-height: 1;
    margin: 15px 10px;
    display: block
}
.tp-leftarrow.default:before {
    content: "\f104"
}

.tp-rightarrow.default:before {
    content: "\f105"
}

.profileinfoscroll ng-scrollbar-y{
    position: absolute !important;
    right: -50px !important;

    .ng-scrollbar{
        background-color: #ebebeb;
    }
    .ng-scrollbar-thumb {
        background-color: #758080;
    }
}

.extra-images-column ng-scrollbar{
    overflow: visible!important;
}

.profileeditscroll ng-scrollbar-y{
    position: absolute !important;
    right: -20px!important;
}

#payment-checkout-data{
    div.mat-form-field-suffix{
        top: -.20rem !important;
        right: -6px !important;
        mat-icon{
            height: 15px;
            width: 15px;

            svg-icon:hover{
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 991px){
    .container {
        max-width: 95%;
    }
}
