$zc-body-color:          #212529 !default;        //same as in bootstrap

$border-radius:         .1rem !default;
$border-radius-lg:      .2rem !default;
$border-radius-sm:      .15rem !default;
$btn-padding-x:         2rem !default;
$nav-header-heigth:     64px;
$mobile-sidebar-width:  15rem;
$highlight-item:        #e1eff7 !default;

$graytext:              #7d7d7e !default;
$blue:                  #4482ec !default;       // Headers

// Colores secundarios
$orange-zc:             #F08A27 !default;
$teal-zc:               #09BB9B !default;
$rosa-zc-dark:          #A41F93 !default;
$red-zc:                #E74C3C !default;

// Inherited
$violet-zc-dark:        #5b2067 !default;
$blue-zc-dark:          #2f5fa3 !default;
$green-zc-dark:         #39756a !default;
$green-zc-darker:       #058478 !default;
$orange-zc-dark:        #de8228 !default;

$separator-gray:        #dad8d8 !default;

// Restyling
$blue-zc:               #389ed0 !default;
$blue-light-zc:         #389ed0 !default;
$rosa-light-zc:         #F7BFDE !default;
$rosa-zc:               #F03A9D !default;
$grey-zc:		       	#758080 !default;
$grey-zc-dark:		    #434343 !default;

$mod-approved-zc:       #41A536 !default;
$mod-pending-zc:	    #EBD14D !default;
$mod-disapproved-zc:	#E74C3C !default;


// z-indexes
$z-ix-mobile-search-mobclose:     1000 !default;

$z-ix-toastr:                    27000 !default;

$z-ix-mobile-search-overlay:     24999 !default;
$z-ix-mobile-search:             25000 !default;

$z-ix-mainnav:                   999 !default;
$z-ix-sidebar-backdrop:          29999 !default;
$z-ix-sidebar:                   30000 !default;

$z-ix-profilegallery-backdrop:   30200 !default;
$z-ix-profilegallery-window:     30201 !default;

$z-ix-userprofile-backdrop:      30299 !default;
$z-ix-userprofile-window:        30300 !default;

$z-ix-messaging-backdrop:        30499 !default;
$z-ix-messaging-window:          30500 !default;

$z-ix-cropper-backdrop:          30510 !default;
$z-ix-cropper:                   30550 !default;

$z-ix-modal-backdrop:            30600 !default;
$z-ix-modal:                     31000 !default;

$z-index-gallery:                31050 !default;

$z-ix-userreport-backdrop:       31080 !default;
$z-ix-userreport-window:         31081 !default;

$z-ix-mktbanner-backdrop:        31149 !default;
$z-ix-mktbanner-window:          31150 !default;

$z-ix-match-backdrop:            49999 !default;
$z-ix-match-window:              50000 !default;
